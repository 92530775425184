.kortti{
   width: 30%;
   min-width: 380px; 
}

@media (max-width: 600px) {
    .kortti {
        min-width: 96%; 
    }
  }

  @media (max-width: 600px) {
    .rivi {
        justify-content: center;
    }
  }